


















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({name: 'SubscriptionPlans'})
export default class extends Vue {
  public $store: any;
  public $q: any;
  public subscriptionPlans: any = [];
  public loading: boolean = true;
  public filter: string = '';
  private columns: any = [
    {
      name: 'enabled',
      required: true,
      label: 'Actif',
      align: 'left',
      field: (row: any) => row.is_deleted ? '' : '✓ ',
      sortable: true,
      headerStyle: 'width: 50px',
    },
    {
      name: 'title_fr',
      required: true,
      label: 'Nom',
      align: 'left',
      field: (row: any) => row.title_fr,
      sortable: true,
      headerStyle: 'max-width: 100px',
    },
    {
      name: 'desc_fr',
      label: 'Description',
      align: 'left',
      field: (row: any) => row.desc_fr,
      sortable: true,
    },
  ];

  public async created() {
    this.refresh();
  }

  private refresh() {
    this.loading = true;
    this.$store.dispatch(
      'loadSubscriptionPlans').then((response: any) => {
      this.subscriptionPlans = response.subscription_plans;
      this.loading = false;
    }).catch((error: any) => {
      this.loading = false;
      this.globalError(error);
    });
  }

}
